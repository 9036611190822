/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




/* -------------- Estilos globales ------------------------------------------*/


// Hacer que los popovers aparezcan siempre al pie de la página (por defecto salen bajo el control que sea y a veces se cortarían por debajo)
ion-popover::part(content) {
    top: unset !important;
    bottom: 0;
    left: 50% !important;
    transform: translateX(-50%) !important;    
    //width: 100vw;
    //border-radius: 0;
}


ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

.color_machos {
    color: #4040ff;
}

.color_hembras {
    color: #ff4040;
}

.letrachica {
    font-size:smaller;
}

.minilabel {
    font-size:smaller;
    opacity: 0.5;
}


/* Estilo para tablas normales: con borde y padding personalizados */

table {
    width: 100%;
}

td {
    padding: 8px 12px;
    border: 1px solid #c0c0c0;
}


/* "tabla_escritorio": Estilo para tablas más compactas, no responsive, suponiendo que se gestionarán en pantallas grandes */

.tabla_escritorio {
    width: 100%;
    margin-top: 12px;
}
.tabla_escritorio td {
    padding: 4px 6px;
    font-size: smaller;
    border: 1px solid #c0c0c0;
}

.tabla_escritorio td.cabecera_tabla {
    padding: 8px;
    border: 1px solid var(--ion-color-secondary);
    background-color: var(--ion-color-secondary);
    color: white;
    //color: black;
}

.chip_boton {
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    border: 1px solid var(--ion-color-primary);
    background-color: var(--ion-color-primary);
    color: white;
    padding: 3px 4px 0px 4px;
    font-size: 14px;
}


.header_explotacion_actual {
    font-size: smaller;
    opacity: 0.75;
    color: black;
    background: var(--ion-color-secondary);
    padding: 8px 8px 6px 24px;
    border-bottom: 1px solid rgba(0,0,0,0.25);
}

.explo_en_listado {
    opacity: 0.75;
    font-size: smaller;
    color: var(--ion-color-primary);
    margin-bottom: 6px;
}



